import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfigValues = {
  apiKey: "AIzaSyB1o0MnyGmGL6xgB5cVg8hqEVBjBCSwZdE",
  authDomain: "ashva-c030d.firebaseapp.com",
  databaseURL: "https://ashva-c030d-default-rtdb.firebaseio.com",
  projectId: "ashva-c030d",
  storageBucket: "ashva-c030d.appspot.com",
  messagingSenderId: "415117945508",
  appId: "1:415117945508:web:7d5e516da7d97e4ebfd431",
  measurementId: "G-84M57L0NPF",
};

const firebaseApp = initializeApp(firebaseConfigValues);
const firebaseAnalytics = getAnalytics(firebaseApp);
const firebaseAuth = getAuth(firebaseApp);
const firebaseDb = getDatabase(firebaseApp);

export { firebaseApp, firebaseAnalytics, firebaseAuth, firebaseDb };
