import React, { useEffect, useState } from "react";

import { signOut } from "firebase/auth";

import {
  firebaseApp,
  firebaseAnalytics,
  firebaseAuth,
  firebaseDb,
} from "../firebase/FirebaseManager";

import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import LanguageIcon from "@mui/icons-material/Language";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import SendIcon from "@mui/icons-material/Send";

import { ref, onValue, push, update, remove, set } from "firebase/database";

import InputAdornment from "@mui/material/InputAdornment";

import {
  MenuItem,
  TextField,
  Select,
  Button,
  ClearIcon,
  FormControl,
  FormLabel,
  Radio,
  FormControlLabel,
  RadioGroup,
  Grid,
  Typography,
  Paper,
  Container,
  CssBaseline,
  Box,
  Divider,
} from "@material-ui/core";

export default function Home() {
  const [fullName, setFullName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [validity, setValidity] = useState("");
  const [userInactive, setUserInactive] = useState(false);
  const [meetUrl, setMeetUrl] = useState("loading");

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleJoin = () => {
    openInNewTab(meetUrl);
  };

  const handleContact = () => {
    openInNewTab("https://www.ashvafitnessclub.com/contact.html");
  };

  const handleLogout = async () => {
    signOut(firebaseAuth);
    localStorage.setItem("UserId", "");
  };

  useEffect(() => {
    const userRef = ref(firebaseDb, "user/" + localStorage.getItem("UserId"));
    const meetInfoRef = ref(firebaseDb, "meetInfo/");

    onValue(userRef, (snapshot) => {
      const data = snapshot.val();

      setFullName(data.fullName);
      setEmailId(data.emailId);
      setValidity(data.expiryDate);

      setUserInactive(false);

      if (data.expiryDate == "") {
        setUserInactive(true);
      } else {
        const current = new Date();

        var dateParts = data.expiryDate.split("-");

        const userExp = new Date(
          dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0]
        );
        userExp.setDate(userExp.getDate() + 1);

        if (userExp < current) {
          setUserInactive(true);
        }
      }
    });

    onValue(meetInfoRef, (snapshot) => {
      const data = snapshot.val();
      setMeetUrl(data.meetUrl);
    });
  });

  return (
    <Box
      style={{
        background: "linear-gradient(to right bottom, #BCA8FF, #B2D9FF)",
      }}>
      <Container
        style={{
          minWidth: "100%",
          minHeight: "100vh",
          margin: 0,
        }}>
        <Grid container justifyContent="center" wrap="wrap">
          <Grid item xs={10} sm={8} md={6}>
            <Paper
              elevation={15}
              style={{
                marginTop: 80,
                padding: 30,
              }}>
              <Grid container justifyContent="center" wrap="wrap">
                <Grid item>
                  <img src="/ashva_logo.png" alt="image" />
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20,
                  }}>
                  <Typography align="center" variant="h6">
                    <b>ASHVA &nbsp;FITNESS &nbsp;CLUB</b>
                  </Typography>
                </Grid>
              </Grid>
              <br></br>
              <Typography
                variant="h6"
                style={{
                  marginBottom: 15,
                  color: "blue",
                }}>
                <b>&nbsp;Meeting Link</b>
              </Typography>
              <TextField
                fullWidth
                id="email-id"
                disabled
                style={{
                  backgroundColor: userInactive ? "#fdff99" : "#ffffff",
                }}
                value={
                  userInactive
                    ? "Your account is not active. Please contact us."
                    : "loading" == meetUrl
                    ? "Loading...."
                    : "" == meetUrl
                    ? "No meeting scheduled"
                    : meetUrl
                }
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <InsertLinkIcon />
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 100 }}
              />
              <br></br>
              <br></br>
              <Grid container justifyContent="center" wrap="wrap">
                <Grid item m={6}>
                  {userInactive ? (
                    <Button
                      onClick={handleContact}
                      style={{
                        paddingLeft: 30,
                        paddingRight: 45,
                        textTransform: "none",
                      }}
                      variant="contained"
                      startIcon={<SendIcon />}
                      color="primary">
                      Contact Us
                    </Button>
                  ) : (
                    <Button
                      onClick={handleJoin}
                      style={{
                        paddingLeft: 30,
                        paddingRight: 45,
                        textTransform: "none",
                      }}
                      disabled={"loading" == meetUrl || "" == meetUrl}
                      variant="contained"
                      startIcon={<OndemandVideoIcon />}
                      color="primary">
                      Join Meeting
                    </Button>
                  )}
                </Grid>
              </Grid>

              <br></br>
              <Divider />
              <br></br>

              <Typography
                variant="h6"
                style={{
                  marginBottom: 15,
                  color: "blue",
                }}>
                <b>&nbsp;My Account</b>
              </Typography>

              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end">
                <Grid item>
                  <b>{"" == fullName ? "Loading..." : fullName}</b>
                  <br></br>
                  {emailId}
                  <br></br>
                  {"" == validity ? "" : "Subscription valid till " + validity}
                </Grid>
                <Grid
                  item
                  style={{
                    width: 100,
                    display: "flex",
                    justifyContent: "flex",
                    alignItems: "flex-end",
                  }}>
                  <Button
                    onClick={handleLogout}
                    style={{
                      fontSize: 12,
                      paddingLeft: 45,
                      paddingRight: 45,
                      backgroundColor: "#f08a1d",
                      textTransform: "none",
                    }}
                    variant="contained"
                    color="primary">
                    Logout
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <div style={{ paddingBottom: 5 }}>
        <Typography align="center" variant="body2" style={{ color: "blue" }}>
          Powered by <b>Ashva Fitness Club</b>
        </Typography>
      </div>
    </Box>
  );
}
