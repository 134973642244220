import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  MenuItem,
  TextField,
  Select,
  Button,
  ClearIcon,
  FormControl,
  FormLabel,
  Radio,
  FormControlLabel,
  RadioGroup,
  Grid,
  Typography,
  Paper,
  Container,
  CssBaseline,
  Box,
} from "@material-ui/core";

import InputAdornment from "@mui/material/InputAdornment";

import LoginIcon from "@mui/icons-material/Login";
import EmailIcon from "@mui/icons-material/Email";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";

import { ref, onValue, push, update, remove, set } from "firebase/database";

import {
  firebaseApp,
  firebaseAnalytics,
  firebaseAuth,
  firebaseDb,
} from "../firebase/FirebaseManager";

const validator = require("validator");

export default function SignUp() {
  const [fullName, setFullName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errorField, setErrorField] = useState("");
  const [signingUp, setSigningUp] = useState(false);
  const [signUpErrorText, setSignUpErrorText] = useState("");

  const handleSignUp = async () => {
    setErrorField("");
    setSignUpErrorText("");

    if (fullName.trim().length < 2) {
      setErrorField("full-name");
      return;
    } else if (!validator.isEmail(emailId.trim())) {
      setErrorField("email-id");
      return;
    } else if ("" == password.trim() || 6 > password.trim().length) {
      setErrorField("password");
      return;
    } else if (password.trim() != passwordConfirm.trim()) {
      setErrorField("password-confirm");
      return;
    }

    setSigningUp(true);

    createUserWithEmailAndPassword(
      firebaseAuth,
      emailId.trim(),
      password.trim()
    )
      .then((userCredential) => {
        localStorage.setItem("UserId", userCredential.user.uid);

        set(ref(firebaseDb, "user/" + localStorage.getItem("UserId")), {
          fullName: fullName,
          emailId: emailId,
          isAdmin: false,
          expiryDate: "",
        });

        setSigningUp(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        setSigningUp(false);

        if ("auth/network-request-failed" == errorCode) {
          setSignUpErrorText(
            "Please check your internet connection and try again."
          );
        } else if ("auth/email-already-in-use" == errorCode) {
          setSignUpErrorText("Email ID already exists.");
        } else {
          setSignUpErrorText("Unable to sign up. Please try again later.");
        }
      });
  };

  return (
    <Box
      style={{
        minWidth: "100%",
        minHeight: "100vh",
        background: "linear-gradient(to right bottom, #BCA8FF, #B2D9FF)",
      }}>
      <Container>
        <Grid container justifyContent="center" wrap="wrap">
          <Grid item xs={10} sm={8} md={6}>
            <Paper
              elevation={15}
              style={{
                marginTop: 75,
                marginBottom: 60,
                padding: 30,
              }}>
              <Grid container justifyContent="center" wrap="wrap">
                <Grid item>
                  <img src="/ashva_logo.png" alt="image" />
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20,
                  }}>
                  <Typography align="center" variant="h6">
                    <b>ASHVA &nbsp;FITNESS &nbsp;CLUB</b>
                  </Typography>
                </Grid>
              </Grid>
              <br></br>
              <Typography
                variant="h6"
                style={{
                  marginBottom: 15,
                  color: "blue",
                }}>
                <b>&nbsp;Sign Up</b>
              </Typography>
              <TextField
                fullWidth
                id="full-name"
                variant="outlined"
                label="Full Name"
                onChange={(e) => {
                  setFullName(e.target.value);
                  setErrorField("");
                }}
                disabled={signingUp}
                error={"full-name" == errorField}
                helperText={
                  "full-name" == errorField ? "Enter valid full name" : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 100 }}
              />
              <br></br>
              <br></br>
              <TextField
                fullWidth
                id="email-id"
                variant="outlined"
                label="Emali ID"
                onChange={(e) => {
                  setEmailId(e.target.value);
                  setErrorField("");
                }}
                disabled={signingUp}
                error={"email-id" == errorField}
                helperText={
                  "email-id" == errorField ? "Enter valid Email ID" : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 100 }}
              />
              <br></br>
              <br></br>
              <TextField
                fullWidth
                id="password"
                type="password"
                variant="outlined"
                label="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrorField("");
                }}
                disabled={signingUp}
                error={"password" == errorField}
                helperText={
                  "password" == errorField
                    ? "Password must be minimum 6 characters"
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyIcon />
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 30 }}
              />
              <br></br>
              <br></br>{" "}
              <TextField
                fullWidth
                id="password-confirm"
                type="password"
                variant="outlined"
                label="Confirm Password"
                onChange={(e) => {
                  setPasswordConfirm(e.target.value);
                  setErrorField("");
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    handleSignUp();
                  }
                }}
                disabled={signingUp}
                error={"password-confirm" == errorField}
                helperText={
                  "password-confirm" == errorField
                    ? "Password does not match"
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyIcon />
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 30 }}
              />
              <br></br>
              <Grid container justifyContent="center" wrap="wrap">
                <Grid item m={6} style={{ marginTop: 10 }}>
                  {signUpErrorText != "" ? (
                    <Typography
                      style={{
                        color: "red",
                      }}>
                      {signUpErrorText}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              <Grid container justifyContent="center" wrap="wrap">
                <Grid item m={6} style={{ marginTop: 10, marginBottom: 10 }}>
                  <Button
                    disabled={signingUp}
                    onClick={handleSignUp}
                    style={{
                      paddingLeft: 30,
                      paddingRight: 45,
                      textTransform: "none",
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={
                      signingUp ? <HourglassEmptyIcon /> : <PersonOutlineIcon />
                    }>
                    &nbsp;&nbsp;&nbsp;{" "}
                    {signingUp ? <b>Signing Up...</b> : <b>Sign Up</b>}
                  </Button>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" wrap="wrap">
                <Grid item m={6} style={{ marginTop: 10 }}>
                  Already have an account? <Link to="/"> Login here</Link>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <div style={{ paddingBottom: 5 }}>
          <Typography align="center" variant="body2" style={{ color: "blue" }}>
            Powered by <b>Ashva Fitness Club</b>
          </Typography>
        </div>
      </Container>
    </Box>
  );
}
