// import logo from './logo.svg';
// import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { CssBaseline, Typography } from "@material-ui/core";

import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import { onAuthStateChanged } from "firebase/auth";
import {
  firebaseApp,
  firebaseAnalytics,
  firebaseAuth,
  firebaseDb,
} from "./firebase/FirebaseManager";

import Login from "./login/Login";
import SignUp from "./signup/SignUp";
import ResetPassword from "./reset-password/ResetPassword";
import Home from "./home/Home";

function App() {
  const [userId, setUserId] = useState(localStorage.getItem("UserId", ""));

  const theme = createTheme({
    palette: {
      action: {
        disabledBackground: "#8a75ff",
        disabled: "#dadada",
      },
    },

    typography: {
      h6: {
        fontFamily: "verdana",
      },
    },
  });

  onAuthStateChanged(firebaseAuth, (user) => {
    if (user) {
      setUserId(user.uid);
    } else {
      setUserId("");
    }
  });

  return (
    <div style={{ height: "100vh" }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={"" == userId || null == userId ? <Login /> : <Home />}
            />
            <Route
              exact
              path="/signup"
              element={"" == userId ? <SignUp /> : <Navigate to="/" />}
            />
            <Route
              exact
              path="/reset-password"
              element={"" == userId ? <ResetPassword /> : <Navigate to="/" />}
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
