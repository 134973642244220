import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  MenuItem,
  TextField,
  Select,
  Button,
  ClearIcon,
  FormControl,
  FormLabel,
  Radio,
  FormControlLabel,
  RadioGroup,
  Grid,
  Typography,
  Paper,
  Container,
  CssBaseline,
  Box,
} from "@material-ui/core";

import InputAdornment from "@mui/material/InputAdornment";

import LoginIcon from "@mui/icons-material/Login";
import EmailIcon from "@mui/icons-material/Email";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  firebaseApp,
  firebaseAnalytics,
  firebaseAuth,
  firebaseDb,
} from "../firebase/FirebaseManager";

const validator = require("validator");

export default function Login() {
  const [emailId, setEmailId] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [logginErrorText, setLogginErrorText] = useState("");
  const [logginSuccessText, setLogginSuccessText] = useState("");

  const handleReset = async () => {
    setEmailError(false);
    setLogginErrorText("");
    if (!validator.isEmail(emailId.trim())) {
      setEmailError(true);
      return;
    }
    setLoggingIn(true);

    sendPasswordResetEmail(firebaseAuth, emailId.trim())
      .then((a) => {
        setLogginSuccessText(
          "Email sent. Please check your inbox (Check spam folder also)"
        );
        setLoggingIn(false);
      })
      .catch((e) => {
        setLogginErrorText("Email ID not registered");
        setLoggingIn(false);
      });
  };

  return (
    <Box
      style={{
        background: "linear-gradient(to right bottom, #BCA8FF, #B2D9FF)",
      }}>
      <Container
        style={{
          minWidth: "100%",
          minHeight: "100vh",
          margin: 0,
        }}>
        <Grid container justifyContent="center" wrap="wrap">
          <Grid item xs={10} sm={8} md={6}>
            <Paper
              elevation={15}
              style={{
                marginTop: 80,
                padding: 30,
              }}>
              <Grid container justifyContent="center" wrap="wrap">
                <Grid item>
                  <img src="/ashva_logo.png" alt="image" />
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20,
                  }}>
                  <Typography align="center" variant="h6">
                    <b>ASHVA &nbsp;FITNESS &nbsp;CLUB</b>
                  </Typography>
                </Grid>
              </Grid>
              <br></br>
              <Typography
                variant="h6"
                style={{
                  marginBottom: 15,
                  color: "blue",
                }}>
                <b>&nbsp;Reset Password</b>
              </Typography>
              <TextField
                fullWidth
                id="email-id"
                variant="outlined"
                label="Emali ID"
                onChange={(e) => {
                  setEmailId(e.target.value);
                  setEmailError(false);
                }}
                disabled={loggingIn}
                error={emailError}
                helperText={emailError ? "Enter valid Email ID" : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 100 }}
              />
              <br></br>

              <Grid container justifyContent="center" wrap="wrap">
                <Grid item m={6} style={{ marginTop: 10 }}>
                  {logginErrorText != "" ? (
                    <Typography
                      style={{
                        color: "red",
                      }}>
                      {logginErrorText}
                    </Typography>
                  ) : logginSuccessText != "" ? (
                    <Typography
                      style={{
                        color: "green",
                      }}>
                      {logginSuccessText}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>

              <Grid container justifyContent="center" wrap="wrap">
                <Grid item m={6} style={{ marginTop: 10 }}>
                  <Button
                    disabled={loggingIn}
                    onClick={handleReset}
                    style={{
                      paddingLeft: 30,
                      paddingRight: 45,
                      textTransform: "none",
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={
                      loggingIn ? <HourglassEmptyIcon /> : <LoginIcon />
                    }>
                    &nbsp;&nbsp;&nbsp;{" "}
                    {loggingIn ? <b>Please wait...</b> : <b>Reset Password</b>}
                  </Button>
                </Grid>
              </Grid>

              <Grid container justifyContent="center" wrap="wrap">
                <Grid item m={6} style={{ marginTop: 10 }}>
                  To login <Link to="/"> Click here</Link>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <div style={{ paddingBottom: 5 }}>
        <Typography align="center" variant="body2" style={{ color: "blue" }}>
          Powered by <b>Ashva Fitness Club</b>
        </Typography>
      </div>
    </Box>
  );
}
